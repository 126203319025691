.list > ul {
  list-style: none;
  padding-left: 2rem;
}

.list > ul > li {
  margin-bottom: 0.5rem;
}

:global(.dark) .list > ul > li::before {
  @apply text-white;
}

.list > ul > li::before {
  content: '\2022';
  color: #8a00fc;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.list > ul > li > a {
  color: #2563eb;
}

.list > ul > li > a:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.list > a {
  color: #2563eb;
}

.list > a:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.list > * > * a {
  color: #2563eb;
}

.list > * > * a:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}
