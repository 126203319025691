.markdown > p > a {
  color: #8a00fc;
  text-decoration: underline;
}

.markdown > p > a:hover {
  color: #313234;
}

.markdown > ul {
  list-style: none;
  padding-left: 2rem;
}

.markdown > ul > li::before {
  content: '\2022';
  color: #8a00fc;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.markdown > ul > li > a {
  text-decoration: underline;
}

.markdown > ul > li > a:hover {
  color: #313234;
}
