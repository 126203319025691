.swatch {
  position: relative;
}

.swatchDefault::before {
  content: '';
  position: absolute;
  box-shadow: 0 0 0 4px hsl(0deg 0% 100% / 0%), 0 0 0 4px hsl(0deg 0% 84% / 0%),
    inset 0 2px 4px 2px rgb(0 0 0 / 20%);
  height: 30px;
  width: 30px;
  left: -15px;
  top: -15px;
  right: 0;
  border-radius: 50%;
  z-index: 1;
}

.swatchSmall::before {
  content: '';
  position: absolute;
  box-shadow: 0 0 0 4px hsl(0deg 0% 100% / 0%), 0 0 0 4px hsl(0deg 0% 84% / 0%),
    inset 0 2px 4px 2px rgb(0 0 0 / 20%);
  height: 24px;
  width: 24px;
  left: -12px;
  top: -12px;
  right: 0;
  border-radius: 50%;
  z-index: 1;
}
