.backgroundLight,
.backgroundDark {
  position: relative;
}

.backgroundDark {
  background-color: #1a1c1e;
}

.backgroundDark::before {
  background-image: linear-gradient(
    to right,
    rgba(26, 28, 30, 1),
    rgba(26, 28, 30, 0.85),
    rgba(0, 0, 0, 0)
  );
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 70%;
  content: '';
}

.backgroundLight {
  background-color: rgba(235, 235, 240, 1);
}

.backgroundLight::before {
  background-image: linear-gradient(
    rgba(235, 235, 240, 1),
    rgba(235, 235, 240, 0.85),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 70%;
  content: '';
}
